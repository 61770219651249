import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';

import { connect } from 'react-redux';
import HeroSection from './BAHHeroSection';
import WhyDriveLahSection from './BAHWhyDriveLah';
import IntroSection from './BAHIntroSection';
import ReadyToShareSection from './BAHReadyToShare';
import BenifitsSection from './BAHBenifitsSection';
import FAQSection from './BAHFAQ';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { initiateListACarEventFromTab, pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_USEDRENTALCAL,
  EVENT_LISTACAR_CLICKED_LIST_A_CAR,
  LISTACAR_CREATE_NEW_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import css from './BecomeAHostPage.css';
import { loginOrSignupClick } from '../TopbarContainer/Topbar.duck';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

const BecomeAHostPage = props => {
  const {
    isAuthenticated,
    onManageDisableScrolling,
    onLoginOrSignupClick,
    currentUser
  } = props;
  const onUseRentalCalculator = () => {
    // pushGTMBrowseEvent({
    //   props,
    //   event: EVENT_BROWSE_USEDRENTALCAL,
    // });
  }

  const onListYourCar = async () => {

    // initiateListACarEventFromTab({
    //   props,
    //   eventButtonParam:
    //     [EVENT_LISTACAR_CLICKED_LIST_A_CAR, LISTACAR_CREATE_NEW_BUTTON_ID],
    // });

    try {
      const response = await getEventsByBucket('listacar');
      // console.log('Events fetched successfully:', response.data);
      triggerAnalyticsEvent({
        event_id: event_trigger_ids.LISTACAR_CLICKED_LIST_A_CAR, 
        eventData: response.data, 
        props: {
          ui: {
            button: "Create Listing"
          }
        },
        userId: currentUser && currentUser.id && currentUser.id.uuid
      });
    } catch (error) {
      console.log('Error fetching events:', error);
    }
  };
    // prettier-ignore
    return (
      <StaticPage
        title="Host your car with Drive lah and get paid. List your car for free!"
        schema={[
          {
            '@context': 'http://schema.org',
            '@type': 'BecomeAHost',
            description: 'List your car for free with Drive lah, a safe & secure car sharing app in Singapore. Get booking requests | Rent out your | Get paid',
            name: 'Become A Host',
          },
          {
            '@context': 'https://schema.org',
            '@type': ['VideoObject'],
            'name': 'See how Drive lah works',
            'description': 'Drive lah makes it simple and secure to rent cars from real people. Watch our short how it works video.',
            'contentUrl': 'https://www.youtube.com/watch?v=OaOzjZiVaws&ab_channel=DrivelahCarRental%26CarSharinginSingapore',
            'thumbnailUrl': [
              'https://www.drivelah.sg/static/media/videoLogo.6f135bad.png',
            ],
            "uploadDate": "2019-05-24T08:00:00+08:00",
          },
          {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            'mainEntity': [
              {
                '@type': 'Question',
                'name': 'How much can I earn as a host?',
                'acceptedAnswer': {
                  '@type': 'Answer',
                  'text': '<p>It depends on the number of days you rent out your car and the price you set. On average, people who rent out once a week will likely earn around $5000 a year.  Try our earnings estimation tool above.</p>',
                },
              },
              {
                '@type': 'Question',
                'name': 'Is it safe to rent out my car to a stranger?',
                'acceptedAnswer': {
                  '@type': 'Answer',
                  'text': '<p>Yes. At Drive lah, your peace of mind is of utmost importance to us. We have a robust driver screening process to ensure that your car is driven by a verified driver only. Finally, you are in control as the decision to accept a booking is always yours.</p>',
                },
              },
              {
                '@type': 'Question',
                'name': 'How much does it cost to list a car?',
                'acceptedAnswer': {
                  '@type': 'Answer',
                  'text': '<p>There is no charge for listing your car on Drive lah. It is free for hosts to list & share their car. Drive lah charges the guest for the trip and not the host.</p>',
                },
              },
              {
                '@type': 'Question',
                'name': 'Why should I share my car?',
                'acceptedAnswer': {
                  '@type': 'Answer',
                  'text': '<p>As a car owner, you face a lot of costs such as insurance, depreciation, road tax etc. irrespective of usage. By sharing your car on Drivelah, you get to earn additional income and manage these costs better. Most importantly, car sharing impacts our environment positively by reducing the number of cars on the road.</p>',
                },
              },
              {
                '@type': 'Question',
                'name': 'What protection do I have against car damage?',
                'acceptedAnswer': {
                  '@type': 'Answer',
                  'text': '<p>At Drive lah, we have got you covered fully. Our insurance cover has been designed to protect you and the renter comprehensively during the rental period.</p>',
                },
              },
              {
                '@type': 'Question',
                'name': 'Is it compulsory to rent out if I sign up my car?',
                'acceptedAnswer': {
                  '@type': 'Answer',
                  'text': '<p>No. As a car owner it is your decision whether to accept or reject a booking request. You set the price and you decide who rents your car.</p>',
                },
              },
              {
                '@type': 'Question',
                'name': 'Can the car be driven to Malaysia?',
                'acceptedAnswer': {
                  '@type': 'Answer',
                  'text': '<p>The decision to allow driving to Malaysia is yours. If you don’t allow, you can put it under the rules for renting your car.</p>',
                },
              },
            ],
          },
        ]}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain className={css.staticPageWrapper}>
            {/* <h1 style={{ position: 'absolute', left: -999999, opacity: 0 }}>
              <FormattedMessage id="BecomeAHost.header1" />
            </h1> */}
            <HeroSection
              onUseRentalCalculator={onUseRentalCalculator}
              onListYourCar={onListYourCar}
              listYourCarButtonId={LISTACAR_CREATE_NEW_BUTTON_ID}
            />
            <WhyDriveLahSection
              isAuthenticated={isAuthenticated}
              onLoginOrSignupClick={onLoginOrSignupClick}
            />
            <IntroSection onManageDisableScrolling={onManageDisableScrolling} />
            <BenifitsSection onManageDisableScrolling={onManageDisableScrolling} />
            <FAQSection />
            <ReadyToShareSection />
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    );
  };

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    isAuthenticated,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disabled) =>
    dispatch(manageDisableScrolling(componentId, disabled)),
  onLoginOrSignupClick: (page) => dispatch(loginOrSignupClick({page}))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BecomeAHostPage);
