import React from 'react';
import { fetchFeaturedFlexCar } from '../SearchPage/SearchPage.duck';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import SectionHero from './SectionHero';
import css from './LandingPageFlex.css';
import SectionWhyRentWithFlex from './SectionWhyRentWithFlex';
import SectionCompare from './SectionCompare';
import SectionHowItWorks from './SectionHowItWorks';
import SectionBecomeFlexHost from './SectionBecomeFlexHost';
import SectionHelp from '../LandingPage/SectionHelp';
import SectionFeaturedFlexCar from './SectionFeaturedFlexCar';

import { parse } from '../../util/urlHelpers';
import SectionReview from './SectionReview';
import { compose } from 'redux';
import { connect } from 'react-redux';

const questions = [
  {
    question: 'What is Flex+?',
    answer: `Drive lah Flex+ is a unique car subscription model with earning benefits. You basically can rent at a subsidised rate under one condition: you rent out the car for at least 4 days per month when you don’t need it yourself.`,
  },
  {
    question: 'Why should I get a car on Flex+ subscription?',
    answer: `Flex+ combines the benefits of of leasing - the freedom to own a car for a fixed amount per month, including maintenance and insurance - with attractive pricing, flexible contract duration and a contribution to our planet.
    You can select the subscription terms that are good for you – e.g. premium insurance packages, or additional mileage - to see the total price you need to pay per month.`,
  },
  {
    question: 'How long is the minimum subscription period?',
    answer: `2 months from the start of the contract date. You can terminate usage anytime by giving 30 days’ notice to Drive lah.`,
  },
  {
    question: 'What if I am not able to rent out my car 4 days per month?',
    answer: `If you do not rent out the car for 4 days, there will be a charge of $125 per day you have not fulfilled the 4-day condition. E.g. if you rent out 3 days in the month you will have to pay $125 on top of the monthly subscription price.
    We are confident that if you make your calendar available for at least 20 days including 4 days on weekends (that does not mean you need to rent it out for 20 days), that you will rent it out for 4 days. If you have made it available for at least 20 days and still you were not able to rent it out (assuming that you have not declined or cancelled any booking), the new will waive the $125 charge per day. That is our guarantee to you.`,
  },
  {
    question: 'What if I rent out the car more than 4 days per month?',
    answer: `You will earn from renting out your car for every day beyond 4 days per month, including the daily rental rate, fuel payments for guests that have included fuel, and for excess kms driven.`,
  },
  {
    question: 'What is included in the monthly price?',
    answer: `Your monthly fixed subscription costs covers everything except refueling. That means comprehensive insurance, maintenance, road tax, roadside assistance, 24/7 customer service, driving to Malaysia for selected cars, 1000kms per month, GST are all included.
    You can add insurance and mileage packages and add drivers to the contract. In that case you can have $0 liability in case of an accident, unlimited mileage and an additional driver included in the price.`,
  },
  {
    question: 'How can Drive lah Flex+ prices be so much lower than anywhere else?',
    answer: `Prices on Flex+ start below $900 per month. For every car model prices are much lower than anywhere else and that is for 2 reasons:
    1.   Drive lah wants to make mobility available for everyone in Singapore and therefore keeps the prices low.
    2.   We are the only platform that can help you make rent out your car when you don’t need it yourself. By doing that we increase utilisation of that car and reduce the cost for you.`,
  },
  {
    question: 'Why should I rent out',
    answer: `If your car is not used much or you are waiting to sell it then sign up for Drive lah Flex+. This brings you the following benefits:
    -      Steady earnings: get fixed monthly earnings on your idle car
    -      Hassle free: Just list your car on the platform and we take care of the rest
    -      Insurance cover: if you have private insurance, we provide comprehensive insurance throughout the rental. Your own insurance and NCD will not be impacted
    -      Flexibility: with minimum rental periods, there is no long term commitment required`,
  }
];

const LandingPageFlexComponent = ({ params, location, version: pVersion, currentUser }) => {
  const { search } = location;
  const { version = 'v1' } = parse(search);
  const schemaDescription =
    ' Attractive long term car rental services for your transportation needs with Drive lah.No hidden costs & fees. 24/7 support & service. Fast & easy booking.';
  return (
    <Page title="Next level of car ownership" className={css.root} description={schemaDescription}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer transparent />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <SectionHero version={pVersion || version} currentUser={currentUser} isFromFlexPage={true} />
          {/* <ChannelsListings /> */}
          <SectionReview />
          <SectionWhyRentWithFlex />
          <SectionCompare />
          <SectionHowItWorks />
          <SectionFeaturedFlexCar />
          <SectionBecomeFlexHost />
          <SectionHelp data={questions} />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const mapDispatchToProps = () => ({});

const LandingPageFlex = compose(connect(mapStateToProps, mapDispatchToProps))(
  LandingPageFlexComponent
);

LandingPageFlex.loadData =  () => {
  // Use the function from SearchPage.duck.js
  return fetchFeaturedFlexCar();
};

export default LandingPageFlex;
