import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import css from './SingpassCallback.css';
import { useSnackbar } from 'notistack';

const SingpassCallback = () => {
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  const state = new URLSearchParams(search).get('state');
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchClientData = async () => {
      setLoading(true);
      try {
        const codeVerifier = sessionStorage.getItem('codeVerifier'); // Make sure this is set during the initial flow.
        let sessionUserData = JSON.parse(sessionStorage.getItem('CURRENT_USER'));
        let apiUserId = '';
        if(sessionUserData && typeof sessionUserData === 'object') {
          apiUserId = sessionUserData.id.uuid
        }
        const clientResponse = await axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/api/singpass/getIndentityPersonData`,
          {
            authCode: code,
            codeVerifier: codeVerifier, // Include the codeVerifier if required by your backend.
            flexUserId: apiUserId, // Add if needed for user identification.
          }
        );

        if (clientResponse.status === 200) {
          enqueueSnackbar('Data fetched successfully!', { variant: 'success' });
          history.push('/account/driving-verification/singpass-profile');
        } else {
          enqueueSnackbar('Failed to retrieve data. Redirecting...', { variant: 'error' });
          history.push('/account/driving-verification');
        }
      } catch (error) {
        console.error('Error fetching client data:', error);
        enqueueSnackbar('An error occurred. Please try again.', { variant: 'error' });

        // If embedded in a React Native WebView
        if (window.ReactNativeWebView) {
          const data = JSON.stringify({ status: false });
          window.ReactNativeWebView.postMessage(data);
        } else {
          history.push('/account/driving-verification');
        }
      } finally {
        setLoading(false);
      }
    };

    if (code) {
      fetchClientData();
    } else {
      enqueueSnackbar('Authorization code or state is missing.', { variant: 'warning' });
      history.push('/account/driving-verification');
    }
  }, [code, state, enqueueSnackbar, history]);

  return (
    <div className={css.container}>
      {loading ? (
        <>
          <div className={css.loader}></div>
          <h3>We are fetching your verification data from Singpass...</h3>
        </>
      ) : (
        <h3>Redirecting...</h3>
      )}
    </div>
  );
};

export default SingpassCallback;
