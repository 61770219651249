import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  Footer,
  InsuranceDisclaimer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import { connect } from 'react-redux';
import HowItWork from './BAGHowItWork';
import HeroSection from './BAGHeroSection';
import HowToRentSection from './BAGHowToRent';
import WhyDriveLahSection from './BAGWhyDriveLah';
import ReadyToRentSection from './BAGReadyToRent';
import BenifitsSection from './BAGBenifitsSection';
import FAQSection from './BAGFAQ';
import { manageDisableScrolling } from '../../ducks/UI.duck';

import css from './BecomeAGuestPage.css';
import { loginOrSignupClick } from '../TopbarContainer/Topbar.duck';

const BecomeAGuestPage = props => {
  const {
    isAuthenticated,
    onManageDisableScrolling,
    onLoginOrSignupClick,
  } = props;

  // prettier-ignore
  return (
    <StaticPage
      title="Rent verified cars from individual owners across Singapore with Drive lah"
      schema={[
        {
          '@context': 'http://schema.org',
          '@type': 'BecomeAGuest',
          description: 'Save upto 30-40% with Drive lah | Find cars near you | 24/7 road assistance | Insurance for all Drive lah trips',
          name: 'How to rent a car',
        },
        {
          '@context': 'https://schema.org',
          '@type': ['VideoObject'],
          'name': 'See how Drive lah works',
          'description': 'Drive lah makes it simple and secure to rent cars from real people. Watch our short how it works video.',
          'contentUrl': 'https://www.youtube.com/watch?v=OaOzjZiVaws&ab_channel=DrivelahCarRental%26CarSharinginSingapore',
          'thumbnailUrl': [
            'https://www.drivelah.sg/static/media/videoLogo.6f135bad.png',
          ],
          "uploadDate": "2019-05-24T08:00:00+08:00",
        },
        {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          'name': 'FAQ',
          'mainEntity': [
            {
              '@type': 'Question',
              'name': 'What does peer to peer car sharing mean?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': '<p>This means that individuals that own a car can rent out their car to other individuals when they don\'t need their car. The trip will be comprehensively insured for the host\'s and guest\'s peace of mind</p>',
              },
            },
            {
              '@type': 'Question',
              'name': 'What are my key benefits with Drive lah?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': '<p>Drive lah is the most convenient access to cars in Singapore. We offer the largest variety of cars nearby at a click of a button. Drive lah utilizes unused car hours of individual car owners and that makes rentals on Drive lah typically 30-40% cheaper than traditional rental companies</p>',
              },
            },
            {
              '@type': 'Question',
              'name': 'What protection do I have against car damage?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': '<p>All trips are comprehensively insured, which means full cover for 3rd party damages (people & property), and includes damages to the rental car and theft, and any injuries.</p>',
              },
            },
            {
              '@type': 'Question',
              'name': 'Can I cancel booking?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': '<div>Absolutely. You can cancel the booking in line with our <a href="https://help.drivelah.sg/en/articles/2776909-trip-cancellation-policy">cancellation policy</a>. There may be charges depending upon when you cancel the booking.</div>',
              },
            },
            {
              '@type': 'Question',
              'name': 'Am I eligible to drive?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': '<div>All Singapore citizens/ PRs above 22 years with a valid SG license for 2 years or more are eligible. EP holders above 30 years of age with a min 5 year driving experience are eligible as well. For detailed conditions, please click<a href="https://help.drivelah.sg/en/articles/2776896-am-i-eligible-to-rent-a-car-on-drive-lah"> here.</a></div>',
              },
            },
            {
              '@type': 'Question',
              'name': 'Can I take pets with me in the car?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': 'This decision is that of the host. In the car rules you can see whether the owner allows you take pets. Under each listing details, it is mentioned whether pets are allowed. You can also directly check with the host just in case.',
              },
            },
            {
              '@type': 'Question',
              'name': 'Can I travel to Malaysia?',
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': 'This decision is that of the host. In the car rules you can see whether the owner allows you to drive to Malaysia. Under each listing details, it is mentioned whether the car is allowed to be driven to Malaysia or not.',
              },
            },
          ],
        },
      ]}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          {/* <h1 style={{ position: 'absolute', left: -999999, opacity: 0 }}>
              <FormattedMessage id="HowToBookACar.header1" />
            </h1> */}
          <HeroSection />
          <HowToRentSection
            isAuthenticated={isAuthenticated}
            onLoginOrSignupClick={onLoginOrSignupClick}
          />
          <HowItWork onManageDisableScrolling={onManageDisableScrolling} />
          <BenifitsSection />
          <WhyDriveLahSection isAuthenticated={isAuthenticated} onManageDisableScrolling={onManageDisableScrolling} />
          <ReadyToRentSection
            isAuthenticated={isAuthenticated}
            onLoginOrSignupClick={onLoginOrSignupClick}
          />
          <FAQSection />
          <InsuranceDisclaimer />
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;

  return {
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disabled) =>
    dispatch(manageDisableScrolling(componentId, disabled)),
  onLoginOrSignupClick: (page) => dispatch(loginOrSignupClick({page}))
});

export default connect(mapStateToProps, mapDispatchToProps)(BecomeAGuestPage);
