import React, { useEffect, useState } from 'react';
import axios from 'axios';
import css from './VerificationDefault.css';
import { FormattedMessage } from 'react-intl';
import SingpassButton from '../../../assets/SinpassPrimaryButton.svg';
import { createRawPropertiesForGTM, getEventsByBucket } from '../../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../../util/analyticsConstants';

const VerificationDefault = ({ currentUser, selectVerificationProcess }) => {
  const [configData, setConfigData] = useState({});
  const [trxnNumber, setTrxnNumber] = useState(Math.floor(Math.random() * 100000));
  const [userId, setUserId] = useState('');

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);

  const redirectToMyInfo = () => {
    if (configData && configData.authApiUrl) {
      const authUrl = `${configData.authApiUrl}?response_type=code&client_id=${encodeURIComponent(configData.clientId)}&redirect_uri=${encodeURIComponent(configData.redirectUrl)}&scope=${encodeURIComponent(configData.scope)}&state=${encodeURIComponent(configData.state)}&nonce=${encodeURIComponent(configData.nonce)}&code_challenge=${encodeURIComponent(configData.code_challenge)}&code_challenge_method=S256`;
      window.location.href = authUrl;
    }
  };

  useEffect(() => {
    const getEnvData = async () => {
      try {
        const envResponse = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/singpass/config`);
        if (envResponse.status === 200) {
          setConfigData(envResponse.data);
          console.log('Environment data fetched:', envResponse.data);
  
          // Store the code_verifier in sessionStorage
          if (envResponse.data.code_verifier) {
            sessionStorage.setItem('codeVerifier', envResponse.data.code_verifier);
          }
        } else {
          console.error('Failed to fetch environment data:', envResponse.status);
        }
      } catch (error) {
        console.error('Failed to fetch environment data:', error);
      }
    };
  
    const sessionUserData = JSON.parse(sessionStorage.getItem('CURRENT_USER'));
  
    if (currentUser && currentUser.id && currentUser.id.uuid) {
      setTrxnNumber(`${currentUser.id.uuid}:${trxnNumber}`);
    } else if (sessionUserData && typeof sessionUserData === 'object') {
      setTrxnNumber(`${sessionUserData.id.uuid}:${trxnNumber}`);
    } else if (sessionUserData && typeof sessionUserData !== 'object') {
      setTrxnNumber(`${sessionUserData}:${trxnNumber}`);
    }
  
    getEnvData();
  }, [setTrxnNumber, setUserId, currentUser]);

  const handleSingpassClick = async () => {
    console.log("Config data", configData);
    // Check if the required configuration data is present
    if (configData.authApiUrl && configData.clientId && configData.redirectUrl && configData.code_challenge) {
      const authoriseUrl = `${configData.authApiUrl}?response_type=code&client_id=${encodeURIComponent(configData.clientId)}&redirect_uri=${encodeURIComponent(configData.redirectUrl)}&scope=${encodeURIComponent(configData.scope)}&state=${encodeURIComponent(configData.state)}&nonce=${encodeURIComponent(configData.nonce)}&code_challenge=${encodeURIComponent(configData.code_challenge)}&code_challenge_method=S256`;
      console.log('Redirecting to:', authoriseUrl);
      window.location.href = authoriseUrl;
    } else {
      console.error('Authorization URL or other config data is missing');
    }
  };
  

  return (
    <>
      <div className={css.verificationDescription}>
        <FormattedMessage id="Verification.VerifcationDescription" />
      </div>
      <div className={css.verifyBox}>
        <div className={css.verifyBoxTop}>
          <div className={css.recomendedBanner}>
            <span>
              <FormattedMessage id="Verification.VerifcationRecomendedBanner" />
            </span>
          </div>
          <div className={css.verifyMethodDetails}>
            <FormattedMessage id="Verification.SingpassDesc" />
            &nbsp;
            <a
              href="https://api.singpass.gov.sg/library/myinfo/business/introduction"
              className={css.learnMoreLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="NRICSettingForm.learnMore" />
            </a>
          </div>
          <div className={css.verifyMethodAction}>
            <button className={css.verifySingpassButton} onClick={handleSingpassClick}>
              <img src={SingpassButton} alt="Singpass Button" />
            </button>
          </div>
        </div>
        <div className={css.verifyBoxBottom}>
          <ul>
            <li>
              <FormattedMessage id="Verification.SingpassPros1" />
            </li>
            <li>
              <FormattedMessage id="Verification.SingpassPros2" />
            </li>
          </ul>
        </div>
      </div>
      <div className={css.verifyBox}>
        <div className={css.verifyBoxTop}>
          <div className={css.verifyMethodDetails}>
            <FormattedMessage id="Verification.ManualDesc" />
          </div>
          <div className={css.verifyMethodAction}>
            <button className={css.verifyManualButton} onClick={selectVerificationProcess}>
              <FormattedMessage id="Verification.ManualButtonLabel" />
            </button>
          </div>
        </div>
        <div className={css.verifyBoxBottom}>
          <ul>
            <li>
              <FormattedMessage id="Verification.ManualPros1" />
            </li>
            <li>
              <FormattedMessage id="Verification.ManualPros2" />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default VerificationDefault;
